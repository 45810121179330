"use client";
import { sendGTMEvent } from "@next/third-parties/google";

export default function useTracking() {
  const track = (event: string, properties = {}) => {
    sendGTMEvent({ event, ...properties });
  };

  const click = (event: string, properties = {}) => {
    sendGTMEvent({ event: `clickTo ${event}`, ...properties });
  };

  const navigate = (event: string, properties = {}) => {
    sendGTMEvent({ event: `goTo ${event}`, ...properties });
  };

  const page = (pageName: string, properties = {}) => {
    sendGTMEvent({ event: `Page Visited: ${pageName}`, ...properties });
  };

  return { track, click, navigate, page };
}
