"use client";
import { I18N_NAMESPACE } from "@/config/i18n";
import { Logo } from "../Logo";
import { useTranslations } from "next-intl";

const Error500 = () => {
  const t = useTranslations(I18N_NAMESPACE.NOT_FOUND);
  return (
    <div
      className="h-screen flex items-center justify-center bg-cover bg-center relative -mb-10"

    >
      <div className="p-4 max-w-3xl text-center text-white h-auto flex flex-col items-center">
        <div className="mb-14">
          <Logo componentName={"Error500"} />
        </div>

        <div className="content-info">
          <h1 className="h1 text-2xl md:text-4xl font-bold">
            {t("500-internal-server-error")}
          </h1>

          <div className="text-base text-white mt-6 md:mt-8 mx-auto max-w-md">
            <p>{t("500-the-server-encountered")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error500;
