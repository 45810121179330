"use client";

import { FC, ReactNode } from "react";
import Image from "next/image";
import { useTheme } from "next-themes";
import Link from "next/link";

import useTracking from "./Hooks/useTracking";

type Props = {
  className?: string;
  componentName?: string;
  logoClassName?: string;
  theme?: string;
  flat?: boolean;
};
export const Logo: FC<Props> = ({
  className,
  flat = false,
  componentName = "",
  logoClassName = "",
  theme,
}) => {
  // const { theme } = useTheme();
  const { click } = useTracking();

  // console.log("/img/pazuru-black.svg", "theme");
  if (flat) {
    // console.log(
    //   theme == "dark" ? "/img/pazuru-black.svg" : "/img/pazuru-white.svg",
    //   "themetheme"
    // );
    return (
      <>
        <Link
          href="/"
          passHref
          className={className}
          onClick={() => {
            click(`Main Logo Img`, {
              component: "Logo",
              clickFrom: componentName || "Main Logo",
            });
          }}
        >
          <Image
            src={
              theme === "dark" ? "/img/logo-flat-black.svg" : "/img/logo.svg"
            }
            alt="logo"
            className={`w-auto !h-10 sm:!h-10 !relative ${logoClassName}`}
            loading="eager"
            fill
          />
        </Link>
      </>
    );
  } else {
    return (
      <>
        <Link
          href="/"
          passHref
          className={className}
          onClick={() => {
            click(`Main Logo Img`, {
              component: "Logo",
              clickFrom: componentName || "Main Logo",
            });
          }}
        >
          <Image
            src={
              theme === "dark" ? "/img/logo-light.svg" : "/img/logo-light.svg"
            }
            alt="logo"
            className={`w-auto !h-6 sm:!h-8 !relative ${logoClassName}`}
            loading="eager"
            fill
          />
        </Link>
      </>
    );
  }
};
